.top {
  background: $dark; /* IE6-9 */
  background-image: radial-gradient((circle at 50% 0, $dark-blue-grey-three, $dark));
  color: $white;

  .container {
    position: relative;

    .top__img {
      position: absolute;
      right: 0;
      top: 10%;
      z-index: 0;

    }

  }

  .top__circles-bg {
    padding-top: em(72);
    padding-bottom: em(300);
    background: url("../img/circles.svg") no-repeat 32% top;
  }

  .bottom__circles-bg {
    background: url("../img/circles.png") no-repeat right bottom;
  }

  .top__text {
    position: relative;
    padding-top: em(210);
    z-index: 1;

    p {
      margin-top: em(22,18);
      padding-right: 25%;
    }

    .btn-main {
      margin-top: em(77,18);
    }

  }

  @media screen and (min-width: $screen-md) and (max-width: $screen-lg) {
    .container {

      .top__img {
        width: 55%;
        top: 20%;
      }

    }

  }

  @media screen and (max-width: $screen-xs) {

    .top__circles-bg {
      padding-bottom: em(170);
    }

    .top__text {
      padding-top: em(170);
    }

  }

}

