.about {
  background-color: $white;
  padding-top: em(200);

  &.edge--top {
    @include angle(1deg, before, false);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    right: 0;
    width: 0;
    height: 0;
    text-indent: -9999px;
    border-bottom: 110px solid transparent;
    border-right: 230px solid $dark;
    transform: skewY(1deg);
  }

  .h1 {
    max-width: rem(695,14);
  }

  .about__row {
    padding-top: em(130);
    padding-bottom: em(189);

    .paragraph {
      padding: 0 em(37,18);
    }

  }

  .about__img {
    position: relative;

    .bg-img {
      position: absolute;
      width: em(228);
      height: em(228);
      z-index: -1;

      &.center {
        width: em(390);
        height: em(390);
        @include hv-align;
      }

      &.left, &.right {
        @include v-align;
      }

      &.left {
        left: 45%;
      }

      &.right {
        right: 45%;
      }

    }

  }

  @media screen and (max-width: $screen-md-max) {

    .about__row {
      padding-bottom: em(129);

      .paragraph {
        padding: 0 em(15,18);
      }

    }

    .about__img {

      .bg-img {

        &.left, &.right {
          width: 100%;
          @include hv-align;
        }

      }

    }

  }

  @media screen and (max-width: $screen-xs-max) {

    .about__row {

      > [class*="col-"]:not(:last-of-type) {
        margin-bottom: em(80);
      }

    }

    .about__img {

      .bg-img {

        &.center {
          width: 100%;
        }

      }

    }

  }

  @media screen and (max-width: $screen-xs - 1) {

    .about__row {

      .paragraph {
        width: 100% !important;
        left: auto;
      }

    }

  }

}