footer {

  padding-bottom: em(123);
  margin-top: em(186);

  .copyright {
    font-size: em(18);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.78;
    letter-spacing: normal;
    color: $white;
    margin-top: em(24,18);
    opacity: 0.45;
  }

  @media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    padding-bottom: em(90);
    margin-top: em(153);
  }

  @media screen and (max-width: $screen-xs-max) {
    padding-bottom: em(60);
    margin-top: em(120);
  }

}