.description {
  position: relative;
  overflow: hidden;

  .description__text {
    padding-bottom: em(60);

    .paragraph {

      &:first-of-type {
        margin-top: em(40,18);
      }

      &:last-of-type {
        margin-top: em(50,18);
      }

    }

  }

  @media screen and (min-width: $screen-lg) {

    .description__text {
      padding-top: em(90);

    }

  }

  @media screen and (min-width: $screen-xs) {

    .description__text {
      max-width: 87%;

      .paragraph {
        max-width: 93%;
      }

    }

  }

  @media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {

    .description__text {
      padding-top: em(60);
    }

  }

  @media screen and (max-width: $screen-sm-max) {

    .description__img {
      width: 100%;
    }

  }

}

