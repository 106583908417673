.steps {

  .steps__container {
    position: relative;
    padding-top: em(75);
    padding-bottom: em(113);
    margin-top: em(69);
    overflow: hidden;

    .steps__container-inner {
      position: relative;
      padding: 0 25px;

      .linethrough {
        display: block;
        position: absolute;
        top: 0;
        left: 41%;
        width: 5px;
        height: 100%;
        background: $neon-blue;
        z-index: -1;
      }

      .step {
        width: 100%;
        border-radius: 31px;
        box-shadow: 0 6px 43px -18px $periwinkle;
        background-color: $white;
        padding: em(45);
        margin-top: 33px;

        &:last-child {

          .step__img {
            vertical-align: top;
          }

        }

        .step__img {
          width: em(79);
          vertical-align: middle;
          text-align: center;
        }

        .step__text {
          font-size: em(20);
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.8;
          letter-spacing: -0.4px;
          color: $dark-two;
          padding-left: $grid-gutter-width;
        }

      }

    }

  }



  @media screen and (min-width: $screen-md-min) {

    .steps__container {

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../img/circle-bg.svg") no-repeat 20% top;
        background-size: 64%;
        z-index: -1;
        opacity: 0.7;
      }

      .steps__container-inner {

        .step {
          max-width: 65.5%;
        }

      }

    }

  }

  @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {

    .steps__container {

      &:before {
        top: 8.5%;
        left: 3%;
      }

      .steps__container-inner {

        &:before {
          left: 42%;
        }

      }

    }

  }

  @media screen and (max-width: $screen-md-max) {

    .steps__container {

      .steps__container-inner {
        padding: 0;

        .step {

          .step__img {
            width: em(60);

          }

        }

      }

    }

  }

  @media screen and (max-width: $screen-sm-max) {

    .steps__container {
      padding-top: 0;

      .steps__container-inner {

        .step {
          padding: em(40);
        }

      }

    }

  }

  @media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {

    .steps__container {
      padding-top: 0;

      .steps__container-inner {

        &:before {
          left: em(70);
        }

      }

    }

  }

  @media screen and (max-width: $screen-xs-max) {

    .steps__container {

      .steps__container-inner {

        &:before {
          left: em(60);
        }

        .step {
          padding: em(35);

          .step__text {
            font-size: em(19);
            padding-left: 15px;
          }

        }

      }

    }

  }

}