.pricing {
  background-color: $dark;
  color: $white;
  padding-top: em(142);

  .h1 {
    margin-top: 13px;
  }

  .pricing__row {
    margin-top: em(100);
  }

  .pricing__heading {
    font-size: em(22);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.68;
    letter-spacing: -0.4px;
    color: $neon-blue;
  }

  .pricing__price {
    font-size: em(34);
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.94;
    letter-spacing: normal;
    color: $white;
  }

  .pricing__tile {
    min-height: em(397);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: em(20) em(35) em(30) em(35);

    &.center {
      background: $dark-blue-grey-two;
    }

    p {
      padding: 4px 0;
    }

  }

}

.cta-block {
  padding: em(34) em(42);
  margin-top: em(60);

  .cta-block__img {
    padding-right: em(43);

  }

  .cta-block__text {
    font-size: em(28);
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: -0.5px;
    color: $white;
  }

  .btn-main {
    display: inline-block;
    margin: em(20,18) 0;
  }

}


@media screen and (min-width: $screen-md-min) {

  .pricing {

    .pricing__tile {

      &.left {
        margin-right: -23px;
      }

      &.right {
        margin-left: -23px;
      }

      &.center {
        min-height: em(451);
        margin-top: em(-27);
      }

    }

  }

}

@media screen and (max-width: $screen-sm-max) {

  .pricing {

    .pricing__tile {
      margin-bottom: 30px;
    }

  }

  .cta-block {
    padding: em(35);

    .cta-block__img {
      padding-right: em(30);
    }

    .cta-block__text {
      font-size: em(22);
    }

    .btn-main {
      margin-bottom: 0;
    }

  }

}

@media screen and (max-width: $screen-xs-max) {
  .cta-block {

    .cta-block__img {
      vertical-align: top;
      padding-top: 10px;
      padding-right: em(20);

      img {
        width: em(60);
      }

    }

  }
}