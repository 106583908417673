.form-control {
  height: em(60,18);
  font-size: em(18);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: $white;
  border: none;
  border-radius: 34px;
  background-color: $deep-lavender;
  padding: em(14,18) em(28,18);
  @include form-control-focus($dark);
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 0;

  &:focus {
    background-color: darken($deep-lavender, 20%);
    transition: background-color 0.3s ease-in-out;
  }

}

::placeholder {
  font-size: 1em !important;
  color: $white !important;
  opacity: 0.42;
  line-height: 1.78 !important;
  text-align: left !important;
}

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  -webkit-text-fill-color: $white !important;
  -webkit-box-shadow: 0 0 0px 1000px $deep-lavender inset!important;
  background-color: $deep-lavender !important;


  &:hover, &:active, &:focus {
    -webkit-text-fill-color: $white !important;
    -webkit-box-shadow: 0 0 0px 1000px darken($deep-lavender, 20%) inset!important;
    background-color: $deep-lavender !important;

  }

}