// layout extends

.table_row {
  display: table;
  vertical-align: middle;
  height: 100%;
}

.table_cell {
  display: table-cell;
  vertical-align: middle;
}

.block-center {
  float: none;
  margin: auto;
}

// block stylistic extends

.dark-tile {
  border-radius: 7px;
  background-color: $dark-blue-grey;
}

