button, .btn {
  &:hover, &:focus, &:active, &:visited {
    outline: none !important;
  }
}

.btn-main {
  min-width: em(203,18);
  font-size: em(18);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: $dark;
  text-align: left;
  border-radius: 29px;
  box-shadow: 0 5px 14px -3px rgba(4, 3, 9, 0.87);
  background-color: $neon-blue;
  padding: em(12,18) em(22,18) em(14,18) em(22,18);
  transition: ease-in-out 0.3s;

  &.arrow-down {

    .icon-arrow {
      transform: rotate(90deg);
    }

  }

  > span {
    float: right;
  }

  .icon-arrow {
    width: 16px;
    height: 16px;
    fill: $dark;
    transition: ease-in-out 0.3s;
  }

  &:hover, &:focus {
    background-color: darken($neon-blue, 20%);
    color: $white;
    transition: ease-in-out 0.3s;

    .icon-arrow {
      fill: $white;
      transition: ease-in-out 0.3s;
    }

  }

}