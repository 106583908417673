.modal-open {
  overflow: hidden;
}

.modal-dialog {
  position: relative;
  width: auto;
}

.modal-content {
  background-color: $dark-blue-grey;
  border: none;
  border-radius: 7px;
  box-shadow: none;
  color: $white;
  padding-top: em(60);
  padding-bottom: em(40);
}

.modal-backdrop {
  background-color: $dark;
  opacity: 0.85;

  &.in {
    opacity: 0.85;
  }

}

.modal-header  {
  border: none;
  padding: 0;

  .close {
    font-size: 1em;
    margin: 0;
    position: absolute;
    top: em(36);
    right: em(44);
    opacity: 1;
    z-index: 1;
  }

}

.modal-body {
  position: relative;
  padding: em(27) em(75);

  p {
    margin-top: em(37,18);
  }

}

.modal-footer {
  padding: em(27) em(75);
  text-align: left;
  border-top: none;
  color: $perrywinkle-two;
}

@media (min-width: $screen-sm-min) {
  .modal-dialog {
    width: 90%;
    max-width: em(827);
    margin-top: 10%;
  }

  .modal-content {
    box-shadow: none;
  }

}

@media screen and (max-width: $screen-xs-max) {

  .modal-body, .modal-footer {
    padding: em(27) em(35);
  }

  .modal-header  {

    .close {
      right: em(35);
    }

  }

}
